<template>
  <div class="accounts-setting">
    <div class="page-header">
      <h1>{{ $t('Settings')}} > {{ $t('Account Settings')}}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Account Settings')}}</h2>
      </div>
      <div class="main-account">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Admin Settings')}}</span>
          </div>
          <div class="account">
            <div class="account-email">asiacstvp@outlook.com</div>
            <div class="account-acions">
              <a class="action-link" href="#">Edit</a>
              <a class="action-link" href="#">Delete</a>
            </div>
          </div>
        </el-card>

        <div class="admin-accounts">
          <el-card class="box-card">
            <div class="box-card-header" slot="header">
              <span class="card-title">Manager Accounts</span>
              <el-button type="primary">Add</el-button>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>yulo</td>
                  <td>yulo@asia-pro.com</td>
                  <td>
                    <div class="account-acions">
                      <a class="action-link" href="#">Edit</a>
                      <a class="action-link" href="#">Delete</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-card>
        </div>

        <div class="admin-accounts">
          <el-card class="box-card">
            <div class="box-card-header" slot="header">
              <span class="card-title">Staff Accounts</span>
              <el-button type="primary">Add</el-button>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>sardo</td>
                  <td>sardo@asia-pro.com</td>
                  <td>
                    <div class="account-acions">
                      <a class="action-link" href="#">Edit</a>
                      <a class="action-link" href="#">Delete</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>pansiu</td>
                  <td>pansiu@asia-pro.com</td>
                  <td>
                    <div class="account-acions">
                      <a class="action-link" href="#">Edit</a>
                      <a class="action-link" href="#">Delete</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>gongip</td>
                  <td>gongip@asia-pro.com</td>
                  <td>
                    <div class="account-acions">
                      <a class="action-link" href="#">Edit</a>
                      <a class="action-link" href="#">Delete</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AccountSettings',
  data(){
    return {
      activeName: '',
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.main-account{
  width: 98%;
  margin-left: 20px;
}

.account{
  display: flex;
  .account-email{
    flex: 1;
  }
}

.account-acions{
  .action-link{
    margin-right: 10px;
  }
}
.box-card-header{
  display: flex;
  .card-title{
    flex: 1;
  }
}
.admin-accounts{
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
